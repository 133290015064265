/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/Guard/AuthGuard';
import GuestGuard from 'src/components/Guard/GuestGuard';
// import AdminGuard from './components/Guard/AdminGuard';
// import FireGuard from './components/Guard/FireGuard';
// import AssessorGuard from './components/Guard/AssessorGuard';
// import EngineeringGuard from './components/Guard/EngineeringGuard';
// import MenroGuard from './components/Guard/MenroGuard';
// import MaoGuard from './components/Guard/MaoGuard';
// import MpdoGuard from './components/Guard/Mpdoguard';
// import RhuGuard from './components/Guard/RhuGuard';
// import PesoGuard from './components/Guard/PeroGuard';
// import BarangayGuard from './components/Guard/BarangayGuard';
// import BploGuard from './components/Guard/BploGuard';
// import TreasuryGuard from './components/Guard/TreasuryGuard';
import HousingGuard from './components/Guard/HousingGuard';
import VmsGuard from './components/Guard/VmsGuard';
import AemsGuard from './components/Guard/AemsGuard';
import LCRSGuard from './components/Guard/LCRSGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password',
    component: lazy(() => import('src/views/auth/RecoverPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password/reset-form/:email/:token',
    component: lazy(() =>
      import('src/views/auth/RecoverPasswordView/NewPasswordView/')
    )
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/management/companies',
        component: lazy(() => import('src/views/management/Companies'))
      },
      {
        exact: true,
        path: '/app/management/companies/create',
        component: lazy(() => import('src/views/management/Companies/Create'))
      },
      {
        exact: true,
        path: '/app/management/companies/payment/history',
        component: lazy(() => import('src/views/reports/DashboardView/history'))
      },
      {
        exact: true,
        path: '/app/management/companies/account/soa',
        component: lazy(() => import('src/views/reports/DashboardView/soa'))
      },
      {
        exact: true,
        path: '/app/management/companies/sentattachment/:type/:brgycode',
        component: lazy(() =>
          import('src/views/management/Companies/RequestRegistration')
        )
      },
      {
        exact: true,
        path: '/app/management/companies/edit/:company_code',
        component: lazy(() => import('src/views/management/Companies/Edit'))
      },
      // {
      //   exact: true,
      //   path: '/app/management/companies/assessment/details/currentdue/payment/notifications',
      //   component: lazy(() => import('src/views/management/Companies/Assessment/Details/SampleNotification'))
      // },

      {
        exact: true,
        path: '/app/management/companies/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },

      {
        exact: true,
        path: '/app/management/companies/assessment/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/request/requirements/sent/:assessment_id/:brgycode/:company_name',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Requirements')
        )
      },
      {
        exact: true,
        path: '/app/management/companies/assessment/request/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Request')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/:assessment_code/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Details')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/:assessment_code/:company_code/',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Details')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/paymentsuccessful/:assessment_code/:company_code',
        component: lazy(() =>
          import(
            'src/views/management/Companies/Assessment/Details/PaynamicPaymentSuccessful'
          )
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/currentdue/payments/gcash/redirect/failed',
        component: lazy(() =>
          import(
            'src/views/management/Companies/Assessment/Details/PaymentDialogGcashRedirectFailed'
          )
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/edit/:assessment_code/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Edit')
        )
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  //START

  {
    path: '/housing',
    layout: DashboardLayout,
    guard: HousingGuard,
    routes: [
      {
        exact: true,
        path: '/housing',
        component: () => <Redirect to="/housing/dashboard" />
      },
      {
        exact: true,
        path: '/housing/form',
        component: lazy(() => import('src/views/forms'))
      },
      {
        exact: true,
        path: '/housing/reports',
        component: lazy(() => import('src/views/forms/FormReports'))
      }
    ]
  },
  {
    path: '/vms',
    layout: DashboardLayout,
    guard: VmsGuard,
    routes: [
      {
        exact: true,
        path: '/vms',
        component: () => <Redirect to="/vms/dashboard" />
      },
      {
        exact: true,
        path: '/vms/form',
        component: lazy(() => import('src/views/vms-forms'))
      },
      {
        exact: true,
        path: '/vms/printouts',
        component: lazy(() => import('src/views/vms-forms/printouts'))
      }
    ]
  },
  {
    path: '/aems',
    layout: DashboardLayout,
    guard: AemsGuard,
    routes: [
      {
        exact: true,
        path: '/aems',
        component: () => <Redirect to="/aems/dashboard" />
      },
      {
        exact: true,
        path: '/aems/calendar',
        component: lazy(() => import('src/views/aems'))
      },
      {
        exact: true,
        path: '/aems/expense',
        component: lazy(() => import('src/views/aems/expense'))
      },
      {
        exact: true,
        path: '/aems/endorsement',
        component: lazy(() => import('src/views/aems/endorsement'))
      },
      {
        exact: true,
        path: '/aems/letter',
        component: lazy(() => import('src/views/aems/letter'))
      },
      {
        exact: true,
        path: '/aems/forms',
        component: lazy(() => import('src/views/aems/forms'))
      },
      {
        exact: true,
        path: '/aems/users',
        component: lazy(() => import('src/views/aems/users'))
      },
      {
        exact: true,
        path: '/aems/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      }
      // {
      //   exact: true,
      //   path: '/aems/printouts',
      //   component: lazy(() => import('src/views/aems-forms/printouts'))
      // }
    ]
  },
  {
    path: '/lcrs',
    layout: DashboardLayout,
    guard: LCRSGuard,
    routes: [
      {
        exact: true,
        path: '/lcrs',
        component: () => <Redirect to="/lcrs/dashboard" />
      },
      {
        exact: true,
        path: '/lcrs/reports',
        component: lazy(() => import('src/views/lcrs/reports'))
      },
      {
        exact: true,
        path: '/lcrs/search',
        component: lazy(() => import('src/views/lcrs/search'))
      },
      {
        exact: true,
        path: '/lcrs/forms',
        component: lazy(() => import('src/views/lcrs/forms'))
      },
      {
        exact: true,
        path: '/lcrs/request',
        component: lazy(() => import('src/views/lcrs/request'))
      },
      {
        exact: true,
        path: '/lcrs/digitization',
        component: lazy(() => import('src/views/lcrs/digitization'))
      },
      {
        exact: true,
        path: '/lcrs/digitization-search',
        component: lazy(() => import('src/views/lcrs/digitization-search'))
      },
      {
        exact: true,
        path: '/lcrs/review',
        component: lazy(() => import('src/views/lcrs/review'))
      },
      {
        exact: true,
        path: '/lcrs/approval',
        component: lazy(() => import('src/views/lcrs/approval'))
      },
      {
        exact: true,
        path: '/lcrs/payment',
        component: lazy(() => import('src/views/lcrs/payment'))
      },
      {
        exact: true,
        path: '/lcrs/personnel',
        component: lazy(() => import('src/views/lcrs/personnel'))
      },

      {
        exact: true,
        path: '/lcrs/status',
        component: lazy(() => import('src/views/lcrs/status'))
      },
      {
        exact: true,
        path: '/lcrs/request-reports',
        component: lazy(() => import('src/views/lcrs/request-reports'))
      },
      {
        exact: true,
        path: '/lcrs/request-search',
        component: lazy(() => import('src/views/lcrs/request-search'))
      },
      {
        exact: true,
        path: '/lcrs/request-status',
        component: lazy(() => import('src/views/lcrs/request-status'))
      },
      {
        exact: true,
        path: '/lcrs/request-review',
        component: lazy(() => import('src/views/lcrs/request-review'))
      },
      {
        exact: true,
        path: '/lcrs/request-approval',
        component: lazy(() => import('src/views/lcrs/request-approval'))
      },
      {
        exact: true,
        path: '/lcrs/request-payment',
        component: lazy(() => import('src/views/lcrs/request-payment'))
      },
      {
        exact: true,
        path: '/lcrs/users',
        component: lazy(() => import('src/views/aems/users'))
      },
      {
        exact: true,
        path: '/lcrs/roles',
        component: lazy(() => import('src/views/lcrs/personnel/roles'))
      },
      {
        exact: true,
        path: '/lcrs/dropdowns',
        component: lazy(() =>
          import('src/views/lcrs-guiguinto/admin/dropdowns')
        )
      },
      {
        exact: true,
        path: '/lcrs/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/lcrs/print/:id/:type/:application_id',
        component: lazy(() => import('src/views/lcrs/reports/LCRSPrintLayout'))
      },
      {
        exact: true,
        path:
          '/lcrs/approval/:type/:application_id/:status/:list_status_id/:service_id',
        component: lazy(() => import('src/views/lcrs/approval/LCRSApproval'))
      },
      {
        exact: true,
        path:
          '/lcrs/request-approval/:type/:application_id/:status/:list_status_id/:service_id',
        component: lazy(() =>
          import('src/views/lcrs/request-approval/LCRSRequestApproval')
        )
      },
      {
        exact: true,
        path: '/lcrs/request-status/:type/:application_id/:service_id',
        component: lazy(() =>
          import('src/views/lcrs/request-status/LCRSRequestStatusCheck')
        )
      },
      {
        exact: true,
        path: '/lcrs/status/:type/:application_id/:service_id',
        component: lazy(() => import('src/views/lcrs/status/LCRSStatusCheck'))
      },
      //register start
      {
        exact: true,
        path: '/lcrs/scan-qr',
        component: lazy(() =>
          import('src/views/lcrs-guiguinto/registrar/scan-qr/ScanQRComponent')
        )
      },
      {
        exact: true,
        path: '/lcrs/birth-certificate',
        component: lazy(() =>
          import(
            'src/views/lcrs-guiguinto/registrar/register/reg-birth/RegisterBirthComponent'
          )
        )
      },
      {
        exact: true,
        path: '/lcrs/marriage-contract',
        component: lazy(() =>
          import(
            'src/views/lcrs-guiguinto/registrar/register/reg-marriage/RegisterMarriageComponent'
          )
        )
      },
      {
        exact: true,
        path: '/lcrs/death-certificate',
        component: lazy(() =>
          import(
            'src/views/lcrs-guiguinto/registrar/register/reg-death/RegisterDeathComponent'
          )
        )
      },
      {
        exact: true,
        path: '/lcrs/print/:id/:type',
        component: lazy(() =>
          import('src/views/lcrs-guiguinto/registrar/print')
        )
      }
    ]
  },

  //END
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/ApiCallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  //** ebps routes **//
  {
    path: '/ebps',
    // layout: '',
    routes: [
      {
        exact: true,
        path: '/registration'
        // component: lazy(() => import('src/ebps/users/Registration'))
      },
      {
        exact: true,
        path: '/ebps/login',
        // layout: MainLayout,
        component: lazy(() => import('src/views/auth/LoginView'))
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
