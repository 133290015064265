import React from 'react';

function Logo(props) {
  return (
    <></>
    // <img
    //   alt="Logo"
    //   // src="/static/logo.svg"
    //   src="/static/ebpls_logo.png"
    //   {...props}
    //   width={50}
    // />
  );
}

export default Logo;
