// eslint-disable no-use-before-define
// eslint-disable react/prop-types
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
  // useDispatch,
  useSelector
} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  // Link,
  List,
  ListSubheader,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import {
  // AlignLeft,
  Users,
  UserCheck,
  List as FeatureList
  // Folder,
  // Search
  // PieChart
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
// import { useSnackbar } from 'notistack';
// import { logout } from 'src/actions/accountActions';
// import CropFreeIcon from '@material-ui/icons/CropFree';
// import { useHistory } from 'react-router';
import {
  CropFree,
  // ExitToApp,
  FormatListBulleted,
  FormatListNumbered,
  FormatListNumberedRtl
} from '@material-ui/icons';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, item, depth = 0 }) {
  const key = item.title + depth;
  acc.push(
    <React.Fragment key={key}>
      {/* {console.log("item.owner", item.owner)}
      {console.log("item.currentrole", item.currentrole)} */}
      <NavItem
        // style={{ display: item.currentrole === 'admin' ? "block" : item.owner === item.currentrole ? "block" : item.owner === 'all' ? "block" : "none" }}
        style={{
          display:
            item.currentrole === 'admin'
              ? 'block'
              : item.owner === item.currentrole
              ? // item.currentrole !== 'user-inhouse' ?
                'block'
              : // : "none"
              item.owner === 'personnel'
              ? item.currentrole === 'registrar'
                ? 'block'
                : item.currentrole === 'registrar-reviewer'
                ? 'block'
                : item.currentrole === 'treasurer'
                ? 'block'
                : 'none'
              : item.owner === 'officer'
              ? item.currentrole !== 'applicant'
                ? 'block'
                : 'none'
              : item.owner === 'all'
              ? 'block'
              : 'none'
        }}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
        enabled={item.enabled}
      />
    </React.Fragment>
  );

  return acc;
}

// const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: '100%'
  },
  avatar: {
    cursor: 'pointer',
    width: 250,
    height: 'auto'
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  // const { enqueueSnackbar } = useSnackbar();
  // const dispatch = useDispatch();
  // const history = useHistory();

  // const handleLogout = async () => {
  //   try {
  //     await dispatch(logout());
  //     history.push('/');
  //   } catch (error) {
  //     enqueueSnackbar('Unable to logout', {
  //       variant: 'error'
  //     });
  //   }
  // };

  // const navConfig = [
  //   {
  //     subheader: "Admin Controls",
  //     owner: "admin",
  //     items: [
  //       {
  //         title: 'Users',
  //         icon: Users,
  //         href: '/lcrs/users',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Personnel',
  //         icon: UserCheck,
  //         href: '/lcrs/personnel',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Forms',
  //         icon: AlignLeft,
  //         href: '/lcrs/forms',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   //additional for applicant
  //   {
  //     subheader: "Links",
  //     owner: "applicant",
  //     items: [
  //       {
  //         title: "Philsys ID Verify",
  //         icon: AlignLeft,
  //         href: "/",
  //         link: "https://verify2.philsys.gov.ph/",
  //         enabled: true,
  //       },
  //       {
  //         title: "Philsys ID Tracking",
  //         icon: AlignLeft,
  //         href: "/",
  //         link: "https://tracking.phlpost.gov.ph/",
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   //for approver view
  //   {
  //     subheader: "Registration",
  //     owner: "registrar",
  //     items: [
  //       {
  //         title: 'For Approval',
  //         icon: AlignLeft,
  //         href: '/lcrs/approval',
  //         enabled: true,
  //       },
  //       {
  //         title: "For Release",
  //         icon: AlignLeft,
  //         href: "/lcrs/reports",
  //         enabled: true,
  //       },
  //       {
  //         title: 'Check Status',
  //         icon: UserCheck,
  //         href: '/lcrs/status',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Search',
  //         icon: Search,
  //         href: '/lcrs/search',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   {
  //     subheader: "Request",
  //     owner: "registrar",
  //     items: [
  //       {
  //         title: 'For Approval',
  //         icon: AlignLeft,
  //         href: '/lcrs/request-approval',
  //         enabled: true,
  //       },
  //       {
  //         title: 'For Release',
  //         icon: AlignLeft,
  //         href: '/lcrs/request-reports',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Check Status',
  //         icon: UserCheck,
  //         href: '/lcrs/request-status',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Search',
  //         icon: Search,
  //         href: '/lcrs/request-search',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   {
  //     subheader: "Digitize",
  //     owner: "registrar",
  //     items: [
  //       {
  //         title: 'Search(All)',
  //         icon: Search,
  //         href: '/lcrs/digitization-search',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   //for reviewer view
  //   {
  //     subheader: "Registration",
  //     owner: "registrar-reviewer",
  //     items: [
  //       {
  //         title: 'For Review',
  //         icon: AlignLeft,
  //         href: '/lcrs/review',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Check Status',
  //         icon: UserCheck,
  //         href: '/lcrs/status',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Search',
  //         icon: Search,
  //         href: '/lcrs/search',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   {
  //     subheader: "Request",
  //     owner: "registrar-reviewer",
  //     items: [
  //       {
  //         title: 'For Review',
  //         icon: AlignLeft,
  //         href: '/lcrs/request-review',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Check Status',
  //         icon: UserCheck,
  //         href: '/lcrs/request-status',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Search',
  //         icon: Search,
  //         href: '/lcrs/request-search',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   {
  //     subheader: "Digitize",
  //     owner: "registrar-reviewer",
  //     items: [
  //       {
  //         title: 'Search(All)',
  //         icon: Search,
  //         href: '/lcrs/digitization-search',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   //for treasurer view
  //   {
  //     subheader: "Registration",
  //     owner: "treasurer",
  //     items: [
  //       {
  //         title: 'For Payment',
  //         icon: AlignLeft,
  //         href: '/lcrs/payment',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Check Status',
  //         icon: UserCheck,
  //         href: '/lcrs/status',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Search',
  //         icon: Search,
  //         href: '/lcrs/search',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   {
  //     subheader: "Request",
  //     owner: "treasurer",
  //     items: [
  //       {
  //         title: 'For Payment',
  //         icon: AlignLeft,
  //         href: '/lcrs/request-payment',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Check Status',
  //         icon: UserCheck,
  //         href: '/lcrs/request-status',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Search',
  //         icon: Search,
  //         href: '/lcrs/request-search',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   {
  //     subheader: "Digitize",
  //     owner: "treasurer",
  //     items: [
  //       {
  //         title: 'Search',
  //         icon: Search,
  //         href: '/lcrs/digitization-search',
  //         enabled: true,
  //       },
  //     ],
  //   },

  //   //for user-inhouse view
  //   {
  //     subheader: "Registration",
  //     owner: "user-inhouse",
  //     items: [
  //       {
  //         title: 'Form',
  //         icon: AlignLeft,
  //         href: '/lcrs/forms',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Check Status',
  //         icon: UserCheck,
  //         href: '/lcrs/status',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   {
  //     subheader: "Request",
  //     owner: "user-inhouse",
  //     items: [
  //       {
  //         title: 'Form',
  //         icon: AlignLeft,
  //         href: '/lcrs/request',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Check Status',
  //         icon: UserCheck,
  //         href: '/lcrs/request-status',
  //         enabled: true,
  //       },
  //     ],
  //   },
  //   {
  //     subheader: "Digitize",
  //     owner: "user-inhouse",
  //     items: [
  //       {
  //         title: 'Form',
  //         icon: AlignLeft,
  //         href: '/lcrs/digitization',
  //         enabled: true,
  //       },
  //       {
  //         title: 'Search',
  //         icon: Search,
  //         href: '/lcrs/digitization-search',
  //         enabled: true,
  //       },
  //     ],
  //   },

  //   // {
  //   //   subheader: '',
  //   //   items: [
  //   //     {
  //   //       title: 'Users',
  //   //       icon: Users,
  //   //       href: '/lcrs/users',
  //   //       enabled: true,
  //   //       owner: "admin",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: user.roles[0].name === 'registrar' ? 'Registration Release' : "Search Registration",
  //   //       icon: user.roles[0].name === 'registrar' ? Folder : Search,
  //   //       href: '/lcrs/reports',
  //   //       enabled: true,
  //   //       owner: "personnel",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Registration',
  //   //       icon: AlignLeft,
  //   //       href: '/lcrs/forms',
  //   //       enabled: true,
  //   //       owner: "user-inhouse",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Registration Status',
  //   //       icon: UserCheck,
  //   //       href: '/lcrs/status',
  //   //       enabled: true,
  //   //       owner: "officer",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Digitization',
  //   //       icon: CropFreeIcon,
  //   //       href: '/lcrs/digitization',
  //   //       enabled: true,
  //   //       owner: "user-inhouse",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Registration Review',
  //   //       icon: AlignLeft,
  //   //       href: '/lcrs/review',
  //   //       enabled: true,
  //   //       owner: "registrar-reviewer",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Registration Approval',
  //   //       icon: AlignLeft,
  //   //       href: '/lcrs/approval',
  //   //       enabled: true,
  //   //       owner: "registrar",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Registration Payment',
  //   //       icon: AlignLeft,
  //   //       href: '/lcrs/payment',
  //   //       enabled: true,
  //   //       owner: "treasurer",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Personnel',
  //   //       icon: UserCheck,
  //   //       href: '/lcrs/personnel',
  //   //       enabled: true,
  //   //       owner: "admin",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: user.roles[0].name === 'registrar' ? 'Request Release' : "Search Request",
  //   //       icon: user.roles[0].name === 'registrar' ? Folder : Search,
  //   //       href: '/lcrs/request-reports',
  //   //       enabled: true,
  //   //       owner: "personnel",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Request',
  //   //       icon: AlignLeft,
  //   //       href: '/lcrs/request',
  //   //       enabled: true,
  //   //       owner: "user-inhouse",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Request Status',
  //   //       icon: UserCheck,
  //   //       href: '/lcrs/request-status',
  //   //       enabled: true,
  //   //       owner: "officer",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Request Review',
  //   //       icon: AlignLeft,
  //   //       href: '/lcrs/request-review',
  //   //       enabled: true,
  //   //       owner: "registrar-reviewer",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Request Approval',
  //   //       icon: AlignLeft,
  //   //       href: '/lcrs/request-approval',
  //   //       enabled: true,
  //   //       owner: "registrar",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //     {
  //   //       title: 'Request Payment',
  //   //       icon: AlignLeft,
  //   //       href: '/lcrs/request-payment',
  //   //       enabled: true,
  //   //       owner: "treasurer",
  //   //       currentrole: user.roles[0].name
  //   //     },
  //   //   ]
  //   // },
  // ];

  const navConfig = [
    {
      subheader: 'Admin Controls',
      owner: 'admin',
      items: [
        {
          title: 'Users',
          icon: Users,
          href: '/lcrs/users',
          enabled: true
        },
        {
          title: 'Roles',
          icon: UserCheck,
          href: '/lcrs/roles',
          enabled: true
        },
        {
          title: 'Dropdowns',
          icon: FeatureList,
          href: '/lcrs/dropdowns',
          enabled: true
        }
      ]
    },
    {
      subheader: 'Scan & Registration',
      owner: 'registrar',
      items: [
        {
          title: 'Birth Certificate',
          icon: FormatListBulleted,
          href: '/lcrs/birth-certificate',
          enabled: true
        },
        {
          title: 'Marriage Contract',
          icon: FormatListNumbered,
          href: '/lcrs/marriage-contract',
          enabled: true
        },
        {
          title: 'Death Certificate',
          icon: FormatListNumberedRtl,
          href: '/lcrs/death-certificate',
          enabled: true
        },
        {
          title: 'Scan QR',
          icon: CropFree,
          href: '/lcrs/scan-qr',
          enabled: true
        }
      ]
    },
    {
      subheader: 'Scan',
      owner: 'affiliate',
      items: [
        {
          title: 'QR Code',
          icon: CropFree,
          href: '/lcrs/scan-qr',
          enabled: true
        }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      style={{ backgroundColor: 'green' }}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/lcrs/account">
              <Avatar
                alt="guiguinto official seal"
                className={classes.avatar}
                // src={`${imageLocation}avatar/${user.avatar}`}
                src="/guiguinto_officialseal2.png"
                variant="square"
              />
              {/* <CardMedias
                src="/static/ebpls_logo.png"
                component="img"
                style={{
                  width: '150px'
                }}
                alt=""
              /> */}
            </RouterLink>
          </Box>
          {/* <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/lcrs/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstname} ${user.lastname}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box> */}
        </Box>
        <Divider />
        <Box p={2}>
          {/* {navConfig.map((config, index) => (
            <List
              style={{
                display: 'block',
              }}
              key={index}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))} */}
          {navConfig
            .filter(x => x.owner === user.roles[0].name.trim())
            .map((config, index) => (
              <List
                key={index}
                subheader={
                  <ListSubheader
                    disableGutters
                    disableSticky
                    style={{ color: '#e5e5e5' }}
                  >
                    {config.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
        </Box>
        {/* <Divider />
        <Box
          style={{
            height: 'calc(100% - 550px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: '100%'
          }}
        >
          <Button
            style={{ width: '100%', margin: 15 }}
            onClick={handleLogout}
            startIcon={<ExitToApp size={18} style={{ color: '#fff' }} />}
          >
            <span
              style={{
                width: '100%',
                textAlign: 'left',
                textTransform: 'capitalize',
                color: '#fff'
              }}
            >
              Logout
            </span>
          </Button>
        </Box> */}

        <Hidden xsUp xsDown>
          <Box p={1}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textSecondary" align="center">
                To register your property? Click{' '}
              </Typography>

              <Box mt={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/app/property/register"
                  fullWidth
                >
                  {' '}
                  Register{' '}
                </Button>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
