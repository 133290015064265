import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      <Box style={{ height: 'calc(100vh - 65px)', width: '100vw' }}>
        <Hero />
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: '64px',
            bottom: 0
          }}
        >
          <Typography
            color="textPrimary"
            style={{ fontSize: 14, fontWeight: 700 }}
          >
            Powered By <span style={{ color: 'red' }}>i</span>Concept IT
            SOLUTIONS
          </Typography>
        </Box>
      </Box>
    </Page>
  );
}

export default HomeView;
