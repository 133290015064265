import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function LCRSGuard({ children }) {
    const account = useSelector(state => state.account);

    if (!account.user) {
        return <Redirect to="/login" />;
    }

    if (account.user.roles.length > 0 && account.user.roles[0].name === 'user') {
        return <Redirect to="/app/account" />;
    }

    return children;
}

LCRSGuard.propTypes = {
    children: PropTypes.any
};

export default LCRSGuard;
